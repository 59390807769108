<template>
  <v-progress-linear
    class="awesomeness-score-linear font-weight-medium"
    aria-label="score"
    :model-value="value"
    :color="status.hex"
    height="26"
  >
    <template v-slot:default="{ value }">
      {{ value > -1 ? Math.round(value) : 'N/A' }}
    </template>
  </v-progress-linear>
</template>

<script setup>
import { useStore } from '@/composables/store'

const props = defineProps({
  value: {
    type: [Number, String],
    default: -1,
  },
})

const store = useStore()

const status = computed(() => {
  switch (store.getStatus({ score: props.value })) {
    case 'error':
      return {
        hex: '#ffd4ff',
      }
    case 'warning':
      return {
        hex: '#ff9de5',
      }
    case 'success':
      return {
        hex: '#ff619d',
      }
    default:
      return {
        hex: '#bdbdbd',
      }
  }
})
</script>
